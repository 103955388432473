<template>
    <main class="login-page">
        <div class="login-bg bg-primary animated slideInLeft"></div>

        <Form class="login-form animated fadeIn">
            <div class="animated zoomIn">
                <img src="@/assets/logo.jpg" class="w25 block-center animated rotateIn">
                <h1 class="text-center pd-tb animated slideInDown">SIGN IN</h1>
            </div>

            <Alert type="error" show-icon v-if="error">{{ error }}</Alert>

            <FormItem class="animated slideInLeft">
                <Input ref="username" v-model="form.username" placeholder="Username" @on-enter="submit">
                    <Icon type="md-contact" slot="suffix" />
                </Input>
            </FormItem>
            <FormItem class="animated slideInRight">
                <Input ref="password" type="password" v-model="form.password" placeholder="Password" @on-enter="submit">
                    <Icon type="md-lock" slot="suffix" />
                </Input>
            </FormItem>

            <div class="animated zoomIn">
                <Button type="primary" size="large" long shape="circle" icon="md-log-in" @click="submit">SUBMIT</Button>
            </div>
        </Form>

        <Footer class="w100 pos-abs b0 animated slideInRight" style="background-color: inherit;">
            <copyright class="text-right text-xs text-grey"/>
        </Footer>
    </main>
</template>

<script>
    import Copyright from '@/components/layout/Copyright'

    export default {
        components: {
            Copyright
        },
        data()
        {
            return  {
                form: {
                    username : '',
                    password : ''
                },
                error: null
            }
        },
        mounted()
        {
            this.$refs.username.focus()
        },
        methods: {
            submit()
            {
                if (!this.form.username)
                {
                    this.error = '请输入用户名'
                    return this.$refs.username.focus()
                }

                if (!this.form.password)
                {
                    this.error = '请输入密码'
                    return this.$refs.password.focus()
                }

                this.$refs.username.blur()
                this.$refs.password.blur()
                this.error = null

                this.$http.post('/login/signin', this.form)
                    .then(res => {
                        this.$store.dispatch('login', res)

                        const redirect = this.$route.query.redirect
                        this.$router.replace(redirect ? redirect : '/')
                    })
                    .catch(res => {
                        this.$refs.username.focus()
                    })
            }
        }
    }
</script>

<style>
    .login-page {
        width: 100vw;
        height: 100vh;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .login-bg {
        content: '';
        position: absolute;
        height: 206%;
        width: 97%;
        border-radius: 50%;
        left: -42%;
        z-index: -1;
        top: -47%;
        box-shadow: inset 0 0 51px rgba(0, 0, 0, .1);
    }

    .login-form {
        width: 360px;
        height: auto;
        padding: 25px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 6px;
        background-color: rgba(255, 255, 255, .75);
        box-shadow: 0 0 4px rgba(158, 158, 158, .1);
    }
</style>